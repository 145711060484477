<template>
  <main class="kb-main" id="kb-newcomer">
    <!-- main-header -->
    <div class="main-header">
      <lxp-main-header :show-breadcrumb="true" :show-title="false">
        <template v-slot:action>
          <div class="header-title">
            <h2 class="title">신입행원 온보딩</h2>
<!--            <p class="subtitle">상위 14.2%</p>-->
            <p class="subtitle"></p>
            <!-- is-finish로 수료시 표시 -->
            <div class="title-badges">
              <template v-if="newZMemitems.length > 0">
                <div class="badge badge-primary" v-if="newZMemitems[0].fnshYn === 'Y'">
                  <span class="badge-text">수료</span>
                </div>
                <div class="badge badge-silver" v-else>
                  <span class="badge-text">미수료</span>
                </div>
                <div class="badge badge-primary" v-if="newZMemitems[0].slctnYn === 'Y'">
                  <span class="badge-text">선정</span>
                </div>
                <div class="badge badge-silver" v-else>
                  <span class="badge-text">미선정</span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </lxp-main-header>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <div>
<!--        <h3 class="content-subhead">-->
          <span class="font18 fontBold">차수명</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span v-if="newzTotInfo?.crseNm">{{ newzTotInfo?.crseNm }}</span>
<!--        </h3>-->
      </div>
      <br/>
      <div>
        <!--        <h3 class="content-subhead">-->
        <span class="font18 fontBold">연수기간</span>&nbsp;&nbsp;&nbsp;
        <span v-if="newzTotInfo">{{newzTotInfo?.bgngDt}} ~ {{ newzTotInfo?.endDt}}</span>
        <!--        </h3>-->
      </div>
      <br/><br/>
      <!-- content-section:총점 -->
      <article class="content-section">
        <header class="section-header"><h3 class="title">총점</h3></header>
        <div class="symbol-card-wrap">
          <!-- symbol-card -->
          <div class="symbol-card symbol-card-primary">
            <div class="card-icon"><i class="icon-final"></i></div>
            <div class="card-content">
              <template v-if="newzTotInfo">
                <div class="content-title">{{ (Number(newzTotInfo?.cmmnScore)+Number(newzTotInfo?.selfScore)+Number(newzTotInfo?.jikgunScore)+Number(newzTotInfo?.adtnScore)  )}}점</div>
              </template>
              <template v-else>
                <div class="content-title">-</div>
              </template>
              <div class="content-subhead">최종점수</div>
            </div>
          </div>
          <!-- symbol-card -->
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-activity"></i></div>
            <div class="card-content">
              <template v-if="newzTotInfo">
                <div class="content-title">{{ (Number(newzTotInfo?.cmmnScore)+Number(newzTotInfo?.selfScore)+Number(newzTotInfo?.jikgunScore)  )}}점</div>
              </template>
              <template v-else>
                <div class="content-title">-</div>
              </template>
              <div class="content-subhead">학습활동 점수</div>
            </div>
          </div>
          <!-- symbol-card -->
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-add"></i></div>
            <div class="card-content">
              <template v-if="newzTotInfo">
                <div class="content-title">{{ Number(newzTotInfo?.adtnScore) }}점</div>
              </template>
              <template v-else>
                <div class="content-title">-</div>
              </template>
              <div class="content-subhead">직군별 선택</div>
            </div>
          </div>
          <!-- symbol-card -->
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-unfinished"></i></div>
            <div class="card-content">
              <template v-if="newZMemitems.length > 0">
                <div class="content-title">{{ 0 }}개</div>
              </template>
              <template v-else>
                <div class="content-title">-</div>
              </template>
              <div class="content-subhead">미수료 항목</div>
            </div>
          </div>
        </div>
        <div class="section-bottom">
          <p class="notice-icontext">획득점수 70점 이상 득점자로 상위 10%이내, 무득점 항목이 없는 경우 우수학습자 선정</p>

        </div>
      </article>
      <!-- content-section-group:공통, 자기계발 노력도 -->
      <div class="content-section-group section-divider">
        <div class="group-header">
          <p class="title">※ 항목별 취득점수 클릭 시 상세내용 확인가능</p>
        </div>
      </div>
      <div class="content-section-group " >
        <!-- content-section:공통 -->
        <article class="content-section">
          <header class="section-header"><h3 class="title">공통</h3></header>
          <div class="segment-box segment-box-outline">
            <div class="contents-list">
              <div class="segment-contents">
                <template v-if="newzTotInfo">                  
                  <h5 style="cursor:pointer;" @click="fnShowLrnerDtlScore(newzTotInfo?.cmmnClssCd)">{{ newzTotInfo?.cmmnScore}} <span class="sub">/ {{ newzTotInfo?.cmmnMaxScore}}</span></h5>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>
              </div>
            </div>
          </div>
          <span class="text" style=" position: relative; font: normal 1rem/24px var(--kb-font-KBFGText);"><span style="font-weight: bold">* 공통항목 미수료 시 패널티(해당 직급 기간) : </span>본부부서 공모 및 HR부(인재개발) 주관 연수 공모 선발 시 제외 (단, '영업점 필수 자격증 미취득'으로 인한 미수료의 경우, 연수기간 후 모두 취득 시 페널티 해제)</span>
        </article>

        <!-- content-section:자기계발 노력도 -->
        <article class="content-section">
          <header class="section-header"><h3 class="title">자기계발 노력도</h3></header>
          <div class="segment-box segment-box-outline">
            <div class="contents-list">
              <div class="segment-contents">
                <template v-if="newzTotInfo">
                  <h5 style="cursor:pointer;" @click="fnShowLrnerDtlScore(newzTotInfo?.selfClssCd)">{{ newzTotInfo?.selfScore}} <span class="sub">/ {{ newzTotInfo?.selfMaxScore}}</span></h5>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>               
              </div>
            </div>
          </div>
          <span class="text" style=" position: relative; font: normal 1rem/24px var(--kb-font-KBFGText);"><span style="font-weight: bold">* 회의체 참여 :</span> 연간 최대 5점</span>
        </article>
      </div>
      <!-- content-section-group:직군별 선택 Digital 가점 -->
      <div class="content-section-group group-margin">
        <!-- content-section:직군별 선택 -->
        <article class="content-section">
          <header class="section-header"><h3 class="title">직군별 선택</h3></header>
          <div class="segment-box segment-box-outline">
            <div class="contents-list">
              <div class="segment-contents">
                <template v-if="newzTotInfo">
                  <h5 style="cursor:pointer;" @click="fnShowLrnerDtlScore(newzTotInfo?.jikgunClssCd)">{{ newzTotInfo?.jikgunScore}} <span class="sub">/ {{ newzTotInfo?.jikgunMaxScore}}</span></h5>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>
              </div>
            </div>
          </div>
        </article>
        <!-- content-section:Digital 가점 -->
        <article class="content-section">
          <header class="section-header"><h3 class="title">가점</h3></header>
          <div class="segment-box segment-box-outline">
            <div class="contents-list">
              <div class="segment-contents">
                <template v-if="newzTotInfo">
                  <h5 style="cursor:pointer;" @click="fnShowLrnerDtlScore(newzTotInfo?.adtnClssCd)" >{{ newzTotInfo?.adtnScore}} <span class="sub">/ {{ newzTotInfo?.adtnMaxScore}}</span></h5>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>
              </div>
            </div>
          </div>
        </article>
      </div>
      <!-- content-section:공지사항 -->
      <div class="content-section section-margin">
        <header class="section-header">
          <h3 class="title">공지사항</h3>
          <template v-if="paging.totalCount > 0">
            <div class="header-sub"><router-link :to="{name: 'JuniorNotice', params: {boardType: 'onboarding'}}" class="more-link me-4">전체보기</router-link></div>
          </template>
        </header>
        <div class="search-container">
          <div class="board-list-container">
            <ul class="board-list">
              <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
                <article class="board-row">
                  <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: item.comNtcSn}}" class="board-link"></router-link>
                  <div v-if="item.topFixYn === 'Y'" class="board-column column-index"><em class="text text-gold">필독</em></div>
                  <div v-else class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
  <!--                <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>-->
                  <div class="board-column column-title">
                    <span class="title">{{item.ntcTitle}}</span>
                    <i class="icon-new"></i>
                  </div>
                  <div class="board-column column-date"><span class="text">{{ timestampToDateFormat( item.regDt, 'yyyy-MM-dd')}}</span></div>
                  <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
                </article>
              </li>
            </ul>
            <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
          </div>
          <div v-if="items.length == 0" class="search-result">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png">
              <p class="text">공지사항이 없어요</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <NewzItemDtlScoreModal ref="onboardingModal">

  </NewzItemDtlScoreModal>
</template>

<script setup>
import LxpMainHeader from "@/components/common/LxpMainHeader";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import NewzItemDtlScoreModal from "@/components/newzonboarding/NewzItemDtlScoreModal";
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getItems,
  getPaging,
  initPaging,
  lengthCheck,
  timestampToDateFormat,
} from "@/assets/js/util";
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import {ACT_GET_TOT_LRNER_SCORE} from '@/store/modules/newzonboarding/newz';

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paging = ref(initPaging(route));
    const onboardingModal = ref(null);

    const session = computed(() => store.state.auth.session);
    const items = ref([]);
    const newzTotInfo = ref();
    const newZMemitems = ref([]);

    const getOnboardingNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_ON_BOARDING,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getNewZMemList = () => {
      console.log('session.value: ', session.value);
      store.dispatch(`newz/${ACT_GET_TOT_LRNER_SCORE}`, {
        lrnerId: session.value.lrnerId
      }).then(res => {
        if (lengthCheck(res)) {         
          newzTotInfo.value = getItems(res)[0];

        } else {
          newzTotInfo.value=false;
        }
      }).catch(e => {
        newzTotInfo.value=false;
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    // onBeforeMount(() => {
    //   setParamsByQueryString(route, 'Onboarding', paging, search, true);
    // });

    onMounted(() => {
      getOnboardingNoticeList();
      getNewZMemList();
    });

    const fnShowLrnerDtlScore = (pClssCd) => {
      if(newzTotInfo.value?.distCrseSn ==null || newzTotInfo.value?.distCrseSn=="") return;
      if(pClssCd ==null || pClssCd=="") return;
      const param = {
        distCrseSn : newzTotInfo.value?.distCrseSn,
        newzClssCd : pClssCd,
      }
      onboardingModal.value.fnShowModal(param);
    }

</script>